.gradientHeader {
  background: rgb(8, 140, 208);
  background: linear-gradient(90deg, rgba(8, 140, 208, 1) 0%, rgba(84, 195, 241, 1) 100%);
}

.logo {
  height: 26px;
  width: auto;
}

.submenuHeader :global(.nav-item) :global(a):hover {
  background-color: rgb(8, 140, 208)!important;
  color: #fff!important;
}