.tr {
  border: 1px solid #222;
  border-radius: 5px;
  margin: 5px;
}

.tr :global(td) {
  border: 0px solid #000;
}

:global(.table-responsive) {
  overflow: auto !important;
}


.dTable thead {
  color: #fff !important;
  background-color: #51bcda;
}

.dTable th:nth-child(1){
  border-radius: 10px 0px 0px 10px;
  border: 0px solid #fff;
}

.dTable th:nth-last-child(1){
  border-radius: 0px 10px 10px 0px;
  border: 0px solid #fff;
}


.dTable tr {
  border: 1px solid #fff;
}